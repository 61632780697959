import { z } from 'zod'
import interceptedAxios from '../../../utils/interceptedAxios'

const ZThirtyData = z.object({
  data: z.array(z.object({
    timestamp: z.string(),
    kpi_value: z.number()
  })),
  CalculatedAt: z.string().optional()
})

const ZDataPoint = z.object({
  name: z.string().nullable(),
  count: z.number()
})

const ZThirtyDayData = z.object({
  date: z.string(),
  dataPoint: z.array(ZDataPoint)
})

export const ZThirtyDayDataObject = z.object({
  totalCount: z.number(),
  liveData: z.array(ZThirtyDayData),
  CalculatedAt: z.string().optional()
})

export type ThirtyDayDataObject = z.infer<typeof ZThirtyDayDataObject>

export type thirtyDay = z.infer<typeof ZThirtyData>

function processResponseData (data: ThirtyDayDataObject): thirtyDay {
  const kpiData = data.liveData.map((obj) => {
    return {
      timestamp: obj.date,
      kpi_value: obj.dataPoint[0].count
    }
  })
  const thirtyDayData: thirtyDay = { data: kpiData, CalculatedAt: data.CalculatedAt }
  return thirtyDayData
}

export async function getThirtyDayData (
  tenantId: string,
  from: string,
  to: string,
  onSuccess: (thirtyDayData: thirtyDay, httpStatus: number) => any,
  onFailure: (errorMessage: string, httpStatus: number) => any
): Promise<void> {
  const url = 'kpirest/api/v1/udp/liveData/thirty-days-trend?kpiCategoryId=54c0f6dd-3460-433c-b808-cad310fce27c'
  interceptedAxios.get(url, { timeout: 20000 })
    .then((response) => {
      if (response.status === 200) {
        const parseResponse = ZThirtyDayDataObject.safeParse(response.data.data)
        // istanbul ignore else
        if (parseResponse.success) {
          // istanbul ignore else
          if (parseResponse.data !== null && parseResponse.data !== undefined && parseResponse.data.liveData.length > 0) {
            const kpiData = processResponseData(parseResponse.data)
            onSuccess(kpiData, response.status)
          } else {
            onFailure('No Data Present', response.status)
          }
        } else {
          onFailure(parseResponse.error.message, response.status)
        }
      }
    })
    .catch((error) => {
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      const statusCode = error.response ? error.response.status : 500
      onFailure(error.message, statusCode)
    })
}
