/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* istanbul ignore file */
import React, { useEffect, useRef } from 'react'
import {
  getAmiDataOptions
} from '../../device-details/components/dataSourceComponents/ChartOptions'
import * as HighCharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { useMediaQuery } from 'react-responsive'
import { Card, CardContent, CircularProgress, Grid } from '@mui/material'
import AuthError from '../../../components/ErrorComponents/AuthError'
import LanguageStrings from '../../../i18n/locales'
import { useSelector } from 'react-redux'
import { type RootState } from '../../../store'
import deleteIcon from '../../../assets/deleteIcon.svg'
import { type KpiKey } from '../redux/changeKpiType'
import { getKpiInfo } from './KpiComponents/kpiInfo'

interface GetGraphReturn {
  type: 'error' | 'data'
  element: JSX.Element
}

const DataQuality = (props: { fethThirtyDayData: any, tenant: any, fetchThirtydata: any, handleKpiRemove: (kpi: KpiKey) => void }): JSX.Element => {
  const thirtyDay = useSelector((state: RootState) => state.thirtyDay)
  const ThirtyDayStrings = LanguageStrings().AMIDataCollectionStrings.thirtyDay
  const containerRef = useRef<any>(null)
  const hrRef = useRef<any>(null)
  const titleRef = useRef<any>(null)
  const graphRef = useRef<any>(null)
  const [graphWidth, setGraphWidth] = React.useState<number>(getGraphWidth())

  const isSmallScreen = useMediaQuery({ maxWidth: 2000 })
  const isLargeScreen = useMediaQuery({ minWidth: 2001 })
  const oneMonthAgo = new Date()
  oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1)
  const ChartData: any[][] = []

  const data: any[] = []
  if (thirtyDay.thirtyDayData != null) {
    data.push(thirtyDay.thirtyDayData)

    if (data[0].timestamps !== undefined) {
      const timestamps = []
      for (const entry of data[0].timestamps) {
        const x = new Date(entry).getTime()
        timestamps.push(x)
      }
      const values = data[0].kpiValues
      let temp = []
      for (let i = 0; i < values.length; i++) {
        const formattedDate = formatDate(timestamps[i])
        temp.push(formattedDate)
        temp.push(values[i])
        ChartData.push(temp)
        temp = []
      }
    }
  }

  useEffect(() => {
    setGraphWidth(getGraphWidth())
  }, [window.innerWidth])

  function formatDate (inputDate: any) {
    const date = new Date(inputDate)
    const year = date.getUTCFullYear()
    const month = String(date.getUTCMonth() + 1).padStart(2, '0')
    const day = String(date.getUTCDate()).padStart(2, '0')
    const hours = String(date.getUTCHours()).padStart(2, '0')
    const minutes = String(date.getUTCMinutes()).padStart(2, '0')
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`
    return formattedDate
  }

  function getGraphWidth (): number {
    const width = window.innerWidth
    if (width < 1066) {
      return 170
    } else if (width < 1140) {
      return 190
    } else if (width < 1200) {
      return 230
    } else if (width < 1296) {
      return 257
    } else {
      return 290
    }
  }

  function getFontSize (): string {
    if (window.innerWidth < 1066) {
      return '12px'
    } else if (window.innerWidth < 1200) {
      return '13px'
    } else if (window.innerWidth < 1400) {
      return '14px'
    } else {
      return '16px'
    }
  }

  let cardHeight = '100%' // Default height
  let lineHght = '20px'

  if (isSmallScreen) {
    cardHeight = '45vh' // Height for small screens
    lineHght = '20px'
  }

  if (isLargeScreen) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    cardHeight = '32vh' // Height for large screens
    lineHght = '26px'
  }

  function getLastUpdated (CalculatedAt: string | null): string {
    const timestamp = CalculatedAt !== null ? new Date(CalculatedAt) : ''
    let lastUpdated = ''

    if (timestamp !== '') {
      const gmtTimestamp = new Date(timestamp.toUTCString())

      // Format the hours and minutes
      const hour = gmtTimestamp.getUTCHours() < 10 ? `0${gmtTimestamp.getUTCHours()}` : gmtTimestamp.getUTCHours()
      const minutes = gmtTimestamp.getUTCMinutes() < 10 ? `0${gmtTimestamp.getUTCMinutes()}` : gmtTimestamp.getUTCMinutes()

      // Format the date
      const day = gmtTimestamp.getUTCDate() < 10 ? `0${gmtTimestamp.getUTCDate()}` : gmtTimestamp.getUTCDate()
      const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      const month = monthNames[gmtTimestamp.getUTCMonth()]

      const lastUpdatedTime = `${day} ${month}, ${hour}:${minutes} GMT`

      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      lastUpdated = `${ThirtyDayStrings.LastUpdated}: ${lastUpdatedTime}`
    }

    return lastUpdated
  }

  function getGraph (): GetGraphReturn {
    if (thirtyDay.isLoading) {
      return (
        {
          type: 'error',
          element: (<div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress /></div>)
        }
      )
    } else if (thirtyDay.httpStatus === 401) {
      return (
        {
          type: 'error',
          element: (<div className='authErrorThirtyDaysTend daystrend401'><AuthError errorMessage="Unauthorized"/></div>)
        }
      )
    } else if (thirtyDay.httpStatus === 403) {
      return (
        {
          type: 'error',
          element: (<div className='authErrorThirtyDaysTend daystrend401'><AuthError errorMessage="accessForbidden"/></div>)
        }
      )
    } else if ((thirtyDay.thirtyDayData === null && thirtyDay.httpStatus === 200 && thirtyDay.loadingError === 'No Data Present')) {
      return (
        {
          type: 'error',
          element: (<div className='authErrorThirtyDaysTend daystrend401'><AuthError errorMessage="NoDataPresent"/></div>)
        }
      )
    } else if (
      thirtyDay.thirtyDayData !== undefined &&
      thirtyDay.thirtyDayData !== null
    ) {
      return (
        {
          type: 'data',
          element: (<HighchartsReact
            highcharts={HighCharts}
            options={getAmiDataOptions('#A78D34', ChartData, containerRef, hrRef, titleRef, graphWidth)}
          />)
        }
      )
    } else {
      return (
        {
          type: 'error',
          element: (<div className='authErrorThirtyDaysTend daystrend401'><AuthError errorMessage="cannotFetch" retry={props.fetchThirtydata}/></div>)
        }
      )
    }
  }
  return (
    <div style={{ height: window.innerWidth >= 1896 ? 457 : 382 }}>
      <Card sx={{ height: '100%', borderRadius: '10px', backgroundColor: '#313030', color: '#f0f0f0' }}>
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ fontSize: window.innerWidth > 1300 ? '16px' : '13px', padding: '15px', paddingBottom: 0, letterSpacing: '1px', textTransform: 'uppercase' }}>{ThirtyDayStrings.Title} {getKpiInfo('30 Days Trend')}</div>
            <img onClick={() => { props.handleKpiRemove('30 Days Trend') }} src={deleteIcon} style={{ marginRight: 15, cursor: 'pointer' }} alt='delete'/>
          </div>
          <div style={{ padding: '0px 1px 15px 15px', fontSize: 14, fontWeight: 300 }}>
            <span>
              {getLastUpdated(thirtyDay.calculatedAt)}
            </span>
          </div>
        </div>
        <CardContent sx={{ height: '100%' }}>
          <Grid sx={{ height: '100%' }}>
            <Grid sx={{ height: '100%' }}>
              {
                (getGraph().type === 'error')
                  ? getGraph().element
                  : <Grid>
                  <div ref={containerRef} style={{ display: 'flex', alignItems: 'center', height: '100%', fontSize: getFontSize(), lineHeight: lineHght }}>
                    <hr ref={hrRef} style={{ height: '22px', width: '5px', marginRight: '10px', border: 'none', backgroundColor: '#A78D34' }}/>
                    <div ref={titleRef}>{ThirtyDayStrings.SRR}</div>
                    <div ref={graphRef} className="centerChart" style={{ height: '100%' }}>
                      {getGraph().element}
                    </div>
                  </div>
                </Grid>
              }
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  )
}

export default DataQuality
