/* eslint-disable @typescript-eslint/prefer-optional-chain */
// istanbul ignore file
import React, { useEffect } from 'react'
import { CircularProgress } from '@mui/material'
import AuthError from '../../../components/ErrorComponents/AuthError'
import { type ConnectedProps, connect, useSelector } from 'react-redux'
import { type RootState } from '../../../store'
import { fetchHistoryDonutData } from '../redux/actionCreators'
import { type DonutKpiState } from '../types'
import { buildDonutChartHistoryData, type GeneratedDonutData } from './KpiComponents/BuildKpiHistory'
import { type KpiKeyDonut, type KpiKey, isDonutKpiWihtBar } from '../redux/changeKpiType'
import { getKpiIdFromName, getKpiService } from './KpiComponents/kpiUtils'
import { type DonutSeriesData, getReactApexDonutChart } from './KpiComponents/DonutChart'
import { useCircleGaugeParams } from '../../../customHooks/circleGaugeDim'
import { kpiColorMapDonut } from './KpiComponents/BuildKpiData'
import { getShortenedName } from './KpiComponents/DonutKpi'
import { getReactApexPieChart } from './KpiComponents/PieChart'
import { usePieChartDim } from '../../../customHooks/pieChartDim'

export interface KpiSeriesData {
  name: string
  data: number[]
}

export type KpiDispatchLoader = (kpiType: string, service: string, kpiCategoryId: string) => void

export const getKpiType = (kpiType: KpiKey): string => {
  switch (kpiType) {
    case 'Total Reads':
      return 'total-reads'
    case 'Extended Coverage Level':
      return 'ec-mode-breakdown'
    case 'Signal Breakdown':
      return 'signal-breakdown'
    case 'Alarm Breakdown':
      return 'alarm-breakdown'
    case 'Firmware Breakdown':
      return 'firmware-breakdown'
    case 'Battery Breakdown':
      return 'battery-breakdown'
    case 'Lifecycle Breakdown':
      return 'asset-lifecycle-breakdown'
    default:
      return 'key-not-present'
  }
}

export interface KpiHistoryState <T> {
  isLoading: boolean
  error: boolean
  data: T[] | undefined
  httpStatus: number
}

interface ParentProps {
  selectedDonut: number
  setSelectedDonut: React.Dispatch<React.SetStateAction<number>>
  setBarColor: React.Dispatch<React.SetStateAction<string>>
}

function HistoryDonutChart (props: AllProps): JSX.Element {
  const [height, centerFont] = useCircleGaugeParams()
  const [pieHeight] = usePieChartDim()
  const initialReportData: DonutSeriesData = {
    name: [],
    data: [],
    color: []
  }
  const [reportData, setReportData] = React.useState<DonutSeriesData>(initialReportData)
  const selectedKpi = useSelector((state: RootState) => state.kpiType)

  function kpiData (): GeneratedDonutData {
    console.log(selectedKpi as KpiKeyDonut, props.historyDonutState)
    return buildDonutChartHistoryData(selectedKpi as KpiKeyDonut, props.historyDonutState)
  }

  const kpiLoader = (): KpiDispatchLoader => {
    return props.fetchHistoryDonutData
  }

  const state = (): DonutKpiState => {
    return props.historyDonutState
  }

  const loadKpiData = (): void => {
    const loader = kpiLoader()
    loader(getKpiType(selectedKpi), getKpiService(selectedKpi), getKpiIdFromName(selectedKpi) ?? '')
  }

  useEffect(() => {
    loadKpiData()
  }, [])

  useEffect(() => {
    if (state().httpStatus === 200 && state().data !== undefined) {
      setReportData(kpiData())
    }
  }, [state().data])

  const handleGraphWithFailure = (): JSX.Element => {
    const kpiState = state()
    if (kpiState.isLoading) {
      return (
        <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress /></div>
      )
    } else if (kpiState.httpStatus === 401) {
      return (
        <div className='authErrorThirtyDaysTend daystrend401'><AuthError errorMessage="Unauthorized"/></div>
      )
    } else if (kpiState.httpStatus === 403) {
      return (<div className='authErrorThirtyDaysTend daystrend401'><AuthError errorMessage="accessForbidden"/></div>)
    } else if (kpiState.httpStatus === 200 && kpiState.data === undefined) {
      return (
        <div className='authErrorThirtyDaysTend daystrend401'><AuthError errorMessage="NoDataPresent"/></div>
      )
    } else if (kpiState.data !== undefined && kpiState.httpStatus === 200) {
      return (
        <>
          {isDonutKpiWihtBar(selectedKpi as KpiKeyDonut) ? <></> : reportData.data.length <= 4 ? getReactApexDonutChart(reportData, height, centerFont, props.setSelectedDonut, props.setBarColor, selectedKpi) : getReactApexPieChart(reportData, pieHeight, props.setSelectedDonut, props.setBarColor, selectedKpi)}
          <div className='kpi-details-legends'>
            <table>
              <tbody>
                {reportData.name.map((data, index) => (
                  <tr key={index}>
                    <td style={{ paddingRight: '20px' }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ backgroundColor: kpiColorMapDonut[selectedKpi as KpiKeyDonut][index], width: 4, height: 16 }}></span>
                        <span onClick={() => {
                          props.setSelectedDonut(index)
                          props.setBarColor(kpiColorMapDonut[selectedKpi as KpiKeyDonut][index])
                        }} style={{ marginLeft: 15, fontSize: 12, fontWeight: (index === props.selectedDonut) ? 700 : 500, color: (index === props.selectedDonut) ? '#64C3FF' : '#F0F0F0', cursor: 'pointer', whiteSpace: data.toUpperCase().includes('ECL') ? 'nowrap' : 'initial' }}>{getShortenedName(data)}</span>
                      </div>
                    </td>
                    {/* <td style={{ fontSize: 12 }}>
                      {reportData.data[index].toFixed(2)}%
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )
    } else {
      return (
        <div className='authErrorThirtyDaysTend daystrend401'><AuthError errorMessage="cannotFetch" retry={loadKpiData}/></div>
      )
    }
  }

  return (
    <div className='kpiHistoryContainer' style={{ display: 'flex', alignItems: 'center', borderRadius: 0, padding: 10, border: 'none' }}>
      {handleGraphWithFailure()}
    </div>
  )
}

interface DispatchToProps {
  fetchHistoryDonutData: (kpiType: string, service: string, kpiCategoryId: string) => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  fetchHistoryDonutData: (kpiType: string, service: string, kpiCategoryId: string) => dispatch(fetchHistoryDonutData(kpiType, service, kpiCategoryId))
})
interface StateToProps {
  historyDonutState: DonutKpiState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  historyDonutState: state.historyDonutState
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
type AllProps = ParentProps & PropsFromRedux
export default connector(HistoryDonutChart)
