/* istanbul ignore file */
import * as HighCharts from 'highcharts'
import React from 'react'
import DarkUnica from 'highcharts/themes/dark-unica'
import ReactApexChart, { type Props } from 'react-apexcharts'
import i18Strings from '../../../i18n/en-US.json'
import { type kpiDailyState } from '../redux/kpiDailySlice'
import { type RootState } from '../../../store'
import { connect, type ConnectedProps } from 'react-redux'
import { fetchKpiDailyData } from '../redux/actionCreators'
import { useCircleGaugeParams } from '../../../customHooks/circleGaugeDim'
import { useMediaQuery } from 'react-responsive'
import { Card, CardContent, CircularProgress, Grid } from '@mui/material'
import AuthError from '../../../components/ErrorComponents/AuthError'
import deleteIcon from '../../../assets/deleteIcon.svg'
import { type KpiKey } from '../redux/changeKpiType'
import { getKpiInfo } from './KpiComponents/kpiInfo'
import { type DonutKpiDatapoint } from '../types'

const dataCompletenessStrings = i18Strings.AMIDataCollectionStrings.dataCompleteness

const dataCompletenessKpis = [
  { metricId: 245, legendTxt: dataCompletenessStrings.legend1, idSuffix: 'first' }, // 245 Performance One day electricity register read rate
  { metricId: 454, legendTxt: dataCompletenessStrings.legend2, idSuffix: 'second' }, // 454 Performance Number of electricity meters read (three day registers)
  { metricId: 10058, legendTxt: dataCompletenessStrings.legend3, idSuffix: 'third' }, // 10058 Interval Data Performance Percentage of intervals available for Set 1
  { metricId: 10061, legendTxt: dataCompletenessStrings.legend4, idSuffix: 'fourth' } // 10061 Interval Data Performance Percentage of intervals available for Set 2
]

// Loop over a list of KPI IDs, loop and pull the values from the list of DailyKpi data
// and produce an array of their values for passing to the series data of the chart
function createSeriesData (kpidataArr: DonutKpiDatapoint[]): number[] {
  const kpiValues = []
  // eslint-disable-next-line no-unreachable-loop
  for (let i = 0; i < kpidataArr.length; i++) {
    kpiValues.push(Number(kpidataArr[i].percentage.toFixed(2)))
    break
  }
  return kpiValues
}

function getColorForSnapshot (kpiValue: number): string {
  let color
  if (kpiValue <= 70) {
    color = '#EE3124'
  } else if (kpiValue > 70 && kpiValue <= 90) {
    color = '#F1693E'
  } else {
    color = '#34A762'
  }
  return color
}
function getReactApexChart (dailyKpis: kpiDailyState, height: string, centerFont: string, fetchKpis: () => void): any {
  const dataCompleteness = []
  if (dailyKpis.dataCompleteness?.liveData !== undefined && dailyKpis.dataCompleteness?.liveData.length > 0 &&
      dailyKpis.dataCompleteness?.liveData[0].dataPoint !== undefined && dailyKpis.dataCompleteness?.liveData[0].dataPoint.length > 0) {
    dataCompleteness.push(dailyKpis.dataCompleteness.liveData[0].dataPoint[0])
  }
  const seriesData = createSeriesData(dataCompleteness)
  // calculate the center average
  const chartOptions: Props = {
    series: seriesData,
    options: {
      chart: {
        type: 'radialBar'
      },
      stroke: {
        // This puts a little circle in the chart when it's zero so the
        // chart doesn't look like it's missing data.
        lineCap: 'butt'
      },
      fill: {
        // If there is more series data than colors it repeats
        colors: [getColorForSnapshot(seriesData[0]), '#1271B1', '#4881FE']
      },
      plotOptions: {
        radialBar: {
          dataLabels: {
            name: {
              show: false
            },
            value: {
              show: true,
              fontSize: centerFont,
              fontWeight: 400,
              color: 'white'
            },
            total: {
              show: true,
              label: dataCompletenessStrings.total,
              fontSize: centerFont,
              formatter: function (e) {
                // All of this because the default center avgs the series data
                // but produces a string tha doesn't fit on the screen for cases
                // like 66.3333333 etc.
                // The documentation leaves a lot to be desired. I set a breakpoint to
                // capture the event. I'm assuming here that the config is always present.
                const seriesData = e.config.series
                if (seriesData.length === 0) {
                  return 'No Data'
                } else {
                  const avg = ((seriesData.reduce((partialSum: number, a: number) => partialSum + a, 0)) / seriesData.length)
                  return avg.toFixed(2) + '%'
                }
              }
            }
          },
          track: {
            background: '#202020AA',
            strokeWidth: '45px',
            show: true
          }
        }
      }
    }
  }

  if (dailyKpis.isLoading) {
    return (
      <div style={{ textAlign: 'center' }}>
          <CircularProgress />
      </div>
    )
  } else if (dailyKpis.httpStatus === 200 && dailyKpis.dataCompleteness?.liveData !== undefined && dailyKpis.dataCompleteness?.liveData.length > 0) {
    return (
    <ReactApexChart
      options={chartOptions.options}
      series={chartOptions.series}
      type='radialBar' height={height}
    />
    )
  } else if (dailyKpis.httpStatus === 401) {
    return (
      <div className='authErrorThirtyDaysTend daystrend401'><AuthError errorMessage="Unauthorized"/></div>
    )
  } else if (dailyKpis.httpStatus === 403) {
    return (
        <div className='authErrorThirtyDaysTend daystrend401'><AuthError errorMessage='accessForbidden'/></div>
    )
  } else if (dailyKpis.httpStatus === 502 || dailyKpis.httpStatus === 503) {
    return (
      <div className='authErrorThirtyDaysTend daystrend401'><AuthError errorMessage='cannotFetch' retry={fetchKpis}/></div>
    )
  } else if (dailyKpis.httpStatus === 200 && dailyKpis.dataCompleteness?.liveData !== undefined && dailyKpis.dataCompleteness?.liveData.length === 0) {
    return (
      <div className='authErrorThirtyDaysTend daystrend401'><AuthError errorMessage='NoDataPresent'/></div>
    )
  } else {
    return (
      <div className='authErrorThirtyDaysTend daystrend401'><AuthError errorMessage='cannotFetch' retry={fetchKpis}/></div>
    )
  }
}

// Make an array of legend rows to pass back to the render
function getLegend (dailyKpis: kpiDailyState, httpStatus: any): any {
  const dataCompleteness = []
  if (dailyKpis.dataCompleteness?.liveData !== undefined && dailyKpis.dataCompleteness?.liveData.length > 0 &&
    dailyKpis.dataCompleteness?.liveData[0].dataPoint !== undefined && dailyKpis.dataCompleteness?.liveData[0].dataPoint.length > 0) {
    dataCompleteness.push(dailyKpis.dataCompleteness.liveData[0].dataPoint[0])
  }
  const seriesData = createSeriesData(dataCompleteness)
  const legendRows = []
  // const colors = ['#A78D34', '#1271B1', '#4881FE']
  const isSmallScreen = useMediaQuery({ maxWidth: 2000 })
  const isLargeScreen = useMediaQuery({ minWidth: 2001 })

  let legWidth = '250px' // Default legWidth

  if (isSmallScreen) {
    legWidth = '190px' // legWidth for small screens
  }

  if (isLargeScreen) {
    legWidth = '450px' // legWidth for large screens
  }

  let lastUpdated = ''

  for (let i = 0; i < seriesData.length; i++) {
    const timestamp = dailyKpis.dataCompleteness?.CalculatedAt !== undefined ? new Date(dailyKpis.dataCompleteness?.CalculatedAt) : new Date()
    const gmtTimestamp = new Date(timestamp.toUTCString())

    // Format the hours and minutes
    const hour = gmtTimestamp.getUTCHours() < 10 ? `0${gmtTimestamp.getUTCHours()}` : gmtTimestamp.getUTCHours()
    const minutes = gmtTimestamp.getUTCMinutes() < 10 ? `0${gmtTimestamp.getUTCMinutes()}` : gmtTimestamp.getUTCMinutes()

    // Format the day and month
    const day = gmtTimestamp.getUTCDate() < 10 ? `0${gmtTimestamp.getUTCDate()}` : gmtTimestamp.getUTCDate()
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    const month = monthNames[gmtTimestamp.getUTCMonth()]

    // Create the formatted last updated string
    lastUpdated = ` ${dataCompletenessStrings.lastUpdatedStart}${day} ${month}, ${hour}:${minutes} GMT`

    legendRows.push(
        <tr className='indivLegendRow' key={dataCompletenessKpis[i].metricId}>
            <td><div style={{ width: legWidth }}>{dataCompletenessKpis[i].legendTxt}</div></td>
        </tr>
    )
  }

  if (httpStatus !== 401 && httpStatus !== 403) {
    return { legendRows, lastUpdated }
  } else {
    return []
  }
}

interface ParentProps {
  fetchKpis: () => void
  dataCompRef: React.MutableRefObject<null>
  handleKpiRemove: (kpi: KpiKey) => void
}

const DataCompleteness = (props: AllProps): any => {
  DarkUnica(HighCharts)
  const [height, centerFont] = useCircleGaugeParams()
  const { legendRows, lastUpdated } = getLegend(props.kpiDaily, props.kpiDaily.httpStatus)

  return (
    <div style={{ height: window.innerWidth >= 1896 ? 457 : 382 }}>
      <Card ref={props.dataCompRef} sx={{ height: '100%', borderRadius: '10px', backgroundColor: '#313030', color: '#f0f0f0' }}>
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div data-testid='data-completeness' style={{ fontSize: window.innerWidth > 1300 ? '16px' : '13px', padding: '15px', paddingBottom: 0, letterSpacing: '1px', textTransform: 'uppercase' }}>DATA COMPLETENESS {getKpiInfo('Data Completeness')}</div>
          <img src={deleteIcon} onClick={() => { props.handleKpiRemove('Data Completeness') }} style={{ marginRight: 15, cursor: 'pointer' }} alt='delete'/>
        </div>
        <div style={{ padding: '0px 1px 15px 15px', fontSize: 14, fontWeight: 300 }}>
          <span>
            {lastUpdated}
          </span>
        </div>
      </div>
      <CardContent>
        <Grid>
          <div id='data-comp-container' className='centerChart'>
            <div className='circleChartDataComp'>
              {getReactApexChart(props.kpiDaily, height, centerFont, props.fetchKpis)}
            </div>
            <div className='ami-chart-legends'>
              <table>
                <tbody>
                  {legendRows}
                </tbody>
              </table>
            </div>
          </div>
        </Grid>
      </CardContent>
    </Card>
    </div>
  )
}

interface DispatchToProps {
  fetchKpiDailyData: (tenant: string) => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  fetchKpiDailyData: () => dispatch(fetchKpiDailyData())
})

interface StateToProps {
  kpiDaily: kpiDailyState
  activeTenant: string
}

const mapStateToProps = (state: RootState): StateToProps => ({
  kpiDaily: state.kpiDailyData,
  activeTenant: state.tenant
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
type AllProps = PropsFromRedux & ParentProps
export default connector(DataCompleteness)
